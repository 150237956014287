<template>
  <div>
    <b-breadcrumb class="breadcrumb-slash mb-1">
      <b-breadcrumb-item :to="{ name: 'admin-warehouse-list' }">
        รายการคลังสินค้า
      </b-breadcrumb-item>
      <b-breadcrumb-item active> รายละเอียดคลังสินค้า </b-breadcrumb-item>
    </b-breadcrumb>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-card>
          <b-card-title style="display: flex; justify-content: space-between">
            <h4>รายละเอียดสินค้า</h4>
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-warehousesedit
              variant="warning"
              v-if="isEdit"
            >
              แก้ไขข้อมูล
            </b-button> -->
          </b-card-title>
          <hr />
          <!-- <h5 class="my-2">
            ภาพสินค้า
          </h5>

          <div>
            <el-upload
              ref="upload"
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
          
              :file-list="fileList"
              accept="image/*"
            >
              <i class="el-icon-plus" />
            </el-upload>

            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            
          </div> -->
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ชื่อสินค้า :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="selectedProduct"
                  rules="required"
                >
                  <v-select
                    id="selectedProduct"
                    v-model="selectedProduct"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="productOptions"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="กลุ่มสินค้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <b-form-input
                    id="name"
                    v-model="productDetail.exchangeProductGroup"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="หมวดหมู่สินค้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <b-form-input
                    id="name"
                    v-model="productDetail.productCategory"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="หมายเลขสินค้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="NumberProduct">
                  <b-form-input
                    id="number-product"
                    v-model="productDetail.productCode"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="ราคาสินค้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider #default="{ errors }" name="Price">
                  <b-form-input
                    id="price"
                    v-model="productDetail.price"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="ราคาส่ง :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="barcode"
                  rules="required"
                >
                  <b-form-input
                    id="barcode"
                    v-model="productDetail.minPrice"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-card-footer class="d-flex align-items-center justify-content-end">
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-warehouses
              variant="outline-primary"
            >
              + เพิ่มสต๊อกสินค้า
            </b-button> -->
            <b-button
              :to="{ name: 'admin-warehouse-list' }"
              variant="danger"
              style="width: 10rem"
              class="mr-1"
            >
              กลับ
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-warehousesadd
              variant="primary"
              :disabled="!selectedProduct.value"
            >
              + เพิ่มสต๊อกสินค้า
            </b-button>
          </b-card-footer>
        </b-card>
        <b-card>
          <orders-list :productId="selectedProduct.value" :refetch="refetch" />
          <!-- <b-card-footer class="d-flex align-items-center justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
              style="width: 15rem;"
            >
           
              บันทึกข้อมูล
            </b-button>
          </b-card-footer> -->
        </b-card>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-warehouses"
      centered
      title="เพิ่มสต๊อกสินค้าตัวแทน - พนักงานขาย"
      ok-only
      ok-title="ตกลง"
      @ok="handleModalOk"
      hide-footer
    >
      <validation-observer ref="addsellerRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="ประเภทผู้ใช้งาน :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <v-select
                    v-model="selectedRole"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsRole"
                    id="role"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="ชื่อ -นามสกุล :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Contract"
                  rules="required"
                >
                  <v-select
                    v-model="selectedContract"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsContract"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="วันที่เพิ่มสต๊อก :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="s-datepicker"
                  rules="required"
                >
                  <b-form-datepicker
                    id="s-datepicker"
                    v-model="warehousesSellerModel.date"
                    class="mb-1"
                    locale="th"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="จำนวนที่รับเข้า :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="qtys"
                  rules="required"
                >
                  <b-form-input id="qtys" 
                  v-model="warehousesSellerModel.qty"
                  placeholder="0"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="เอกสารแนบ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sfile"
                  rules="required"
                >
                  <b-form-file
                    v-model="warehousesSellerModel.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="ตำแหน่งคลังสินค้า:"
                label-cols="4"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="SWarehouses"
                  rules="required"
                >
                  <v-select
                    v-model="selectedInventory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="InventoryOptions"
                    id="s-warehouses"
                    
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col style="display: flex; justify-content: end">
              <b-button
                variant="primary"
                @click="handleModalOk"
                style="width: 15rem"
              >
                <!-- <b-spinner
                    v-if="isShowLoading"
                    small
                    class="mr-1"
                  /> -->
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-warehousesadd"
      centered
      title="รายละเอียดการเพิ่มสต๊อกสินค้า"
      ok-only
      ok-title="ตกลง"
      @ok="handleModalOk"
      hide-footer
      ref="modal-warehousesedit"
    >
      <validation-observer ref="modalRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="วันที่เพิ่มสต๊อก :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sdatepicker"
                  rules="required"
                >
                  <b-form-datepicker
                    id="sdatepicker"
                    v-model="warehousesModel.date"
                    class="mb-1"
                    locale="th"
                  />
                  <small  class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="จำนวนที่รับเข้า :"
                label-cols="4"
                label-for="qty"
              >
                <validation-provider
                  #default="{ errors }"
                  name="qty"
                  rules="required"
                >
                  <b-form-input id="qty" placeholder="0" v-model="warehousesModel.qty" />
                  <small v-if="errors[0]" class="text-danger">* กรุณากรอกจำนวน</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="เอกสารแนบ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="wfile"
                  rules="required"
                >
                  <b-form-file
                    v-model="warehousesModel.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="wfile"
                  />
                 <small v-if="errors[0]" class="text-danger">* กรุณาเพิ่มแนบเอกสาร</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-5">
            <b-col md="12">
              <b-form-group
                label="ตำแหน่งคลังสินค้า:"
                label-cols="4"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sWarehouses"
                  rules="required"
                >
                  <v-select
                    v-model="selectedInventory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="InventoryOptions"
                    id="sWarehouses"
                  />

                  <small v-if="errors[0]" class="text-danger">* กรุณาเลือกคลังสินค้า</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col style="display: flex; justify-content: end">
              <b-button
                variant="primary"
                @click="handleModalOk"
                style="width: 15rem"
              >
                <!-- <b-spinner
                    v-if="isShowLoading"
                    small
                    class="mr-1"
                  /> -->
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-warehousesedit"
      centered
      title="แก้ไขข้อมูลสต๊อกสินค้า"
      ok-only
      ok-title="ตกลง"
      @ok="handleModalOk"
      hide-footer
    >
      <validation-observer ref="modalRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="วันที่โอนออก :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sdatepicker"
                  rules="required"
                >
                  <b-form-datepicker
                    id="sdatepicker"
                    v-model="warehousesModel.date"
                    class="mb-1"
                    locale="th"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="จำนวนที่โอนออก :"
                label-cols="4"
                label-for="qty"
              >
                <validation-provider
                  #default="{ errors }"
                  name="qty"
                  rules="required"
                >
                  <b-form-input id="qty" 
                  
                  v-model="warehousesModel.qty" 
                  
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="เอกสารแนบ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="wfile"
                  rules="required"
                >
                  <b-form-file
                    v-model="warehousesModel.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    id="wfile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-5">
            <b-col md="12">
              <b-form-group
                label="ตำแหน่งคลังสินค้า:"
                label-cols="4"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sWarehouses"
                  rules="required"
                >
                  <v-select
                    v-model="selectedInventory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="InventoryOptions"
                    id="sWarehouses"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col style="display: flex; justify-content: end">
              <b-button
                variant="primary"
                @click="handleModalOk"
                style="width: 15rem"
              >
                <!-- <b-spinner
                    v-if="isShowLoading"
                    small
                    class="mr-1"
                  /> -->
                บันทึก
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardFooter,
  BFormCheckbox,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTextarea,
  BBreadcrumb,
  BBreadcrumbItem,
  BModal,
  VBModal,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Cleave from "vue-cleave-component";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { stringToNumber } from "@/common";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import store from "@/store";
import productStoreModule from "./productStoreModule";
import OrdersList from "./OrdersList.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    Cleave,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
    // BTable,
    // BCardHeader,
    BCardTitle,
    BCardText,
    BCardFooter,
    BFormCheckbox,
    BFormDatepicker,
    // BFormRadioGroup,
    // BLink,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BModal,
    VBModal,
    OrdersList,
    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // validation rules
      required,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      dialogImageUrl: "",
      dialogVisible: false,
      optionsTest: [{ value: 1, text: "test" }],
      minDate: new Date(),
      file: null,
      refetch: false,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    // Register module
    if (!store.hasModule("warehouses"))
      store.registerModule("warehouses", productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("warehouses")) store.unregisterModule("warehouses");
    });

    const categoryOptions = [{ label: "", value: "" }];

    const sizeOptions = [{ label: "", value: "" }];

    const colorOptions = [{ label: "", value: "" }];

    const shippings = ref([]);
    const selectedCategory = ref({});
    const selectedBox = ref({});
    const optionsCategory = ref([]);

    const fileList = ref([]);

    // const productModel = ref(null)
    const SubinventoryModel = ref({
      subInventoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      inventoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      contactId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      productId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      productPriceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      qty: 0,
    });

    const warehousesSellerModel = ref({
      roleId: 0,
      roleName: "",
      fullName: "",
      contractId: 0,
      qty: 0,
      file: null,
      warehousesId: 0,
      warehousesName: "",
      date: new Date(),
    });

    const productDetail = ref({
      productCode: "",
      productId: "",
      productName: "",
      price: "",
      minPrice: "",
    });

    const selectedRole = ref({});
    const optionsRole = ref([]);
    const optionsWarehouses = ref([]);
    const selectedWarehouses = ref({});
    const selectedContract = ref({});
    const optionsContract = ref([]);
    const warehousesModel = ref({
      date: new Date(),
      qty: null,
      file: null,
      warehousesId: 0,
      warehousesName: "",
    });

    const isEdit = ref(false);
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        // productModel.value.avatar = base64;
      }
    );

    const productOptions = ref([]);
    const selectedProduct = ref({});
    const fetchProducts = () => {
      store.dispatch("warehouses/fetchProducts").then((response) => {
        response.data.data.forEach((item) => {
          if (item.active == true) {
            if (item.productId == router.currentRoute.params.id) {
              selectedProduct.value = {
                value: item.productId,
                label: item.productName,
                price: item.price,
                productCode: item.productCode,
                productBrand: item.productBrand,
                minPrice: item.minPrice,
                exchangeProductGroup: item.exchangeProductGroup,
                productCategory: item.productCategory.categoryName,
              };
            }
            productOptions.value.push({
              value: item.productId,
              label: item.productName,
              price: item.price,
              productCode: item.productCode,
              productBrand: item.productBrand,
              minPrice: item.minPrice,
              exchangeProductGroup: item.exchangeProductGroup,
              productCategory: item.productCategory.categoryName,
            });
          }
        });
      });
    };
    fetchProducts();

    const InventoryOptions = ref([]);
    const selectedInventory = ref({});
    const fetchInventorys = () => {
      store.dispatch("warehouses/fetchInventorys").then((response) => {
        response.data.data.forEach((item) => {
          if (item.inventoryName !== "คลังตัวแทน") {
            InventoryOptions.value.push({
              value: item.inventoryId,
              label: item.inventoryName,
            });

            selectedInventory.value = {
                "value": "093924d9-01ae-4e15-f6fb-08dae1067fe7",
                "label": "คลังหลัก"
            };
          }
        });
        selectedInventory.value = {
                "value": "093924d9-01ae-4e15-f6fb-08dae1067fe7",
                "label": "คลังหลัก"
            };
      });
    };
    fetchInventorys();

    const fetchContacts = () => {
      store.dispatch("warehouses/fetchContacts").then((response) => {
        console.log("Contacts", response);
      });
    };
    fetchContacts();

    const fetProductById = () => {
      store
        .dispatch("warehouses/fetchProduct", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          console.log("product byid", response);
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    };
    fetProductById();

    const bindingData = () => {
      productDetail.value = {
        productCode: selectedProduct.value.productCode,
        productId: selectedProduct.value.value,
        productName: selectedProduct.value.label,
        price: selectedProduct.value.price.toLocaleString("en-US"),
        productBrand: selectedProduct.value.productBrand,
        minPrice: selectedProduct.value.minPrice.toLocaleString("en-US"),
        exchangeProductGroup: selectedProduct.value.exchangeProductGroup,
        productCategory: selectedProduct.value.productCategory,
      };
    };
    watch([selectedProduct], () => {
      bindingData();
    });

    if (router.currentRoute.name === "admin-warehouse-edit") {
      isEdit.value = true;
      // store
      //   .dispatch("warehouses/fetchSubinventory", {
      //     id: router.currentRoute.params.id,
      //   })
      //   .then((response) => {
      //     selectedProduct.value = {
      //       value: response.data.productId.productId,
      //       label: response.data.productId.productName,
      //       productCode: response.data.productId.productCode,
      //       price: response.data.productId.price,
      //     };
      //     fileList.value.push({
      //       name: response.data.productId.product_Image_Id.media_ID,
      //       url: response.data.productId.product_Image_Id.url,
      //       raw: null,
      //       status: "success",
      //     });
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 404) {
      //     }
      //   });
    }

    return {
      shippings,

      categoryOptions,
      sizeOptions,
      colorOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      fileList,
      optionsCategory,
      selectedCategory,
      selectedBox,
      SubinventoryModel,
      warehousesSellerModel,
      warehousesModel,
      selectedRole,
      selectedWarehouses,
      optionsRole,
      optionsWarehouses,
      selectedContract,
      optionsContract,
      productOptions,
      selectedProduct,
      InventoryOptions,
      selectedInventory,
      productDetail,
      isEdit,
    };
  },
  methods: {
    checkWarehouse(){
      console.log(this.selectedInventory);
    },
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {},
    handleSaveImage(file, fileList) {
      let formData = new FormData(); // Create FormData for upload file

      formData.append(file.uid, file.raw, file.name); // Add file for upload

      store
        .dispatch("warehouses/uploadFileMultiple", formData)
        .then((res) => {
          if (res.status == 200) {
            console.log("res", res.data.data);
          }
        })
        .catch((error) => {
          this.showToast("danger", "XCircleIcon", error);
        });
    },
    handleSaveFile(file, fileList) {
      let formData = new FormData(); // Create FormData for upload file
      console.log("file", file);
      formData.append(file.lastModified, file); // Add file for upload

      store
        .dispatch("warehouses/uploadFileMultiple", formData)
        .then((res) => {
          if (res.status == 200) {
            console.log("res", res.data.data);
          }
        })
        .catch((error) => {
          this.showToast("danger", "XCircleIcon", error);
        });
    },
    validationForm() {
      this.showToast("success", "CheckIcon", "Your Workhas been saved");

      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var saveModel = {};
          if (router.currentRoute.name === "admin-warehouse-edit") {
            saveModel = {
              subInventoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              inventoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              contactId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              productId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              productPriceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              qty: 0,
            };
          } else {
            saveModel = {
              inventoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              contactId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              productId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              productPriceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              qty: 0,
            };
          }
          console.log("saveModel", saveModel);
          console.log("saveModel", JSON.stringify(saveModel));

          store
            .dispatch("warehouses/addSubinventory", saveModel)
            .then((response) => {
              if (response.status === 200) {
                this.$router.push({ name: "product-list" }).then(() => {
                  this.showToast("success", "CheckIcon", "Saved Successfully.");
                });
              }
            })
            .catch((error) => {
              this.showToast("danger", "XCircleIcon", error);
            });
        }
      });
    },
    handleModalOk() {
      // this.handleSaveFile(this.warehousesModel.file)
      const userData = getUserData();
      this.$refs.modalRules.validate().then((success) => {
        if (success) {
          var saveModel = {};
          //console.log("this.selectedInventory", this.selectedInventory);

          saveModel = {
            inventoryId: this.selectedInventory.value,
            contactId: "00000000-0000-0000-0000-000000000000",
            productId: this.selectedProduct.value,
            productPriceId: "00000000-0000-0000-0000-000000000000",
            qty: this.warehousesModel.qty,
            userId: userData.userId,
          };

          /*
          warehousesModel.qty
          warehousesSellerModel.qty
          */
          console.log("saveModel", saveModel);
          console.log("saveModel", JSON.stringify(saveModel));
          
          store 
            .dispatch("warehouses/addSubinventory", saveModel)
            .then((response) => {
              if (response.status == 200) {
                this.$refs["modal-warehousesedit"].hide();
                // this.refetchData()
                this.refetch = !this.refetch;
                this.showToast(
                  "success",
                  "CheckIcon",
                  "Your Workhas been saved"
                );
                this.warehousesModel.qty = '';
              }
            })
            .catch((error) => {
              this.showToast("danger", "XCircleIcon", error);
            });
            
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
