var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"breadcrumb-slash mb-1"},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'admin-warehouse-list' }}},[_vm._v(" รายการคลังสินค้า ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" รายละเอียดคลังสินค้า ")])],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-card',[_c('b-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('h4',[_vm._v("รายละเอียดสินค้า")])]),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ชื่อสินค้า :","label-cols":"4","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"selectedProduct","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"selectedProduct","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.productOptions},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"กลุ่มสินค้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":""},model:{value:(_vm.productDetail.exchangeProductGroup),callback:function ($$v) {_vm.$set(_vm.productDetail, "exchangeProductGroup", $$v)},expression:"productDetail.exchangeProductGroup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"หมวดหมู่สินค้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":""},model:{value:(_vm.productDetail.productCategory),callback:function ($$v) {_vm.$set(_vm.productDetail, "productCategory", $$v)},expression:"productDetail.productCategory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"หมายเลขสินค้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"NumberProduct"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number-product","disabled":""},model:{value:(_vm.productDetail.productCode),callback:function ($$v) {_vm.$set(_vm.productDetail, "productCode", $$v)},expression:"productDetail.productCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ราคาสินค้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","disabled":""},model:{value:(_vm.productDetail.price),callback:function ($$v) {_vm.$set(_vm.productDetail, "price", $$v)},expression:"productDetail.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ราคาส่ง :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"barcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"barcode","disabled":""},model:{value:(_vm.productDetail.minPrice),callback:function ($$v) {_vm.$set(_vm.productDetail, "minPrice", $$v)},expression:"productDetail.minPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card-footer',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"mr-1",staticStyle:{"width":"10rem"},attrs:{"to":{ name: 'admin-warehouse-list' },"variant":"danger"}},[_vm._v(" กลับ ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-warehousesadd",modifiers:{"modal-warehousesadd":true}}],attrs:{"variant":"primary","disabled":!_vm.selectedProduct.value}},[_vm._v(" + เพิ่มสต๊อกสินค้า ")])],1)],1),_c('b-card',[_c('orders-list',{attrs:{"productId":_vm.selectedProduct.value,"refetch":_vm.refetch}})],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-warehouses","centered":"","title":"เพิ่มสต๊อกสินค้าตัวแทน - พนักงานขาย","ok-only":"","ok-title":"ตกลง","hide-footer":""},on:{"ok":_vm.handleModalOk}},[_c('validation-observer',{ref:"addsellerRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"ประเภทผู้ใช้งาน :","label-cols":"4","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.optionsRole,"id":"role"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"ชื่อ -นามสกุล :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Contract","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.optionsContract},model:{value:(_vm.selectedContract),callback:function ($$v) {_vm.selectedContract=$$v},expression:"selectedContract"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"วันที่เพิ่มสต๊อก :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"s-datepicker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"s-datepicker","locale":"th"},model:{value:(_vm.warehousesSellerModel.date),callback:function ($$v) {_vm.$set(_vm.warehousesSellerModel, "date", $$v)},expression:"warehousesSellerModel.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"จำนวนที่รับเข้า :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"qtys","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"qtys","placeholder":"0"},model:{value:(_vm.warehousesSellerModel.qty),callback:function ($$v) {_vm.$set(_vm.warehousesSellerModel, "qty", $$v)},expression:"warehousesSellerModel.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"เอกสารแนบ :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"sfile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.warehousesSellerModel.file),callback:function ($$v) {_vm.$set(_vm.warehousesSellerModel, "file", $$v)},expression:"warehousesSellerModel.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"ตำแหน่งคลังสินค้า:","label-cols":"4","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"SWarehouses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.InventoryOptions,"id":"s-warehouses"},model:{value:(_vm.selectedInventory),callback:function ($$v) {_vm.selectedInventory=$$v},expression:"selectedInventory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('b-button',{staticStyle:{"width":"15rem"},attrs:{"variant":"primary"},on:{"click":_vm.handleModalOk}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1),_c('b-modal',{ref:"modal-warehousesedit",attrs:{"id":"modal-warehousesadd","centered":"","title":"รายละเอียดการเพิ่มสต๊อกสินค้า","ok-only":"","ok-title":"ตกลง","hide-footer":""},on:{"ok":_vm.handleModalOk}},[_c('validation-observer',{ref:"modalRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"วันที่เพิ่มสต๊อก :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"sdatepicker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"sdatepicker","locale":"th"},model:{value:(_vm.warehousesModel.date),callback:function ($$v) {_vm.$set(_vm.warehousesModel, "date", $$v)},expression:"warehousesModel.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"จำนวนที่รับเข้า :","label-cols":"4","label-for":"qty"}},[_c('validation-provider',{attrs:{"name":"qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"qty","placeholder":"0"},model:{value:(_vm.warehousesModel.qty),callback:function ($$v) {_vm.$set(_vm.warehousesModel, "qty", $$v)},expression:"warehousesModel.qty"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("* กรุณากรอกจำนวน")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"เอกสารแนบ :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"wfile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","id":"wfile"},model:{value:(_vm.warehousesModel.file),callback:function ($$v) {_vm.$set(_vm.warehousesModel, "file", $$v)},expression:"warehousesModel.file"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("* กรุณาเพิ่มแนบเอกสาร")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"ตำแหน่งคลังสินค้า:","label-cols":"4","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"sWarehouses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.InventoryOptions,"id":"sWarehouses"},model:{value:(_vm.selectedInventory),callback:function ($$v) {_vm.selectedInventory=$$v},expression:"selectedInventory"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("* กรุณาเลือกคลังสินค้า")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('b-button',{staticStyle:{"width":"15rem"},attrs:{"variant":"primary"},on:{"click":_vm.handleModalOk}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-warehousesedit","centered":"","title":"แก้ไขข้อมูลสต๊อกสินค้า","ok-only":"","ok-title":"ตกลง","hide-footer":""},on:{"ok":_vm.handleModalOk}},[_c('validation-observer',{ref:"modalRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"วันที่โอนออก :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"sdatepicker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"sdatepicker","locale":"th"},model:{value:(_vm.warehousesModel.date),callback:function ($$v) {_vm.$set(_vm.warehousesModel, "date", $$v)},expression:"warehousesModel.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"จำนวนที่โอนออก :","label-cols":"4","label-for":"qty"}},[_c('validation-provider',{attrs:{"name":"qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"qty"},model:{value:(_vm.warehousesModel.qty),callback:function ($$v) {_vm.$set(_vm.warehousesModel, "qty", $$v)},expression:"warehousesModel.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"เอกสารแนบ :","label-cols":"4","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"wfile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","id":"wfile"},model:{value:(_vm.warehousesModel.file),callback:function ($$v) {_vm.$set(_vm.warehousesModel, "file", $$v)},expression:"warehousesModel.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"ตำแหน่งคลังสินค้า:","label-cols":"4","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"sWarehouses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.InventoryOptions,"id":"sWarehouses"},model:{value:(_vm.selectedInventory),callback:function ($$v) {_vm.selectedInventory=$$v},expression:"selectedInventory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('b-button',{staticStyle:{"width":"15rem"},attrs:{"variant":"primary"},on:{"click":_vm.handleModalOk}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }