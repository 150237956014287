import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    //
    { key: "createDate", label: "วันที่/ เวลา",
    formatter: (val) => `${val.toLocaleString("en-US").replace('T',' ').substring(0,19)}`, },
    { key: "stockMaster", label: "การเพิ่มสต๊อก",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
    { key: "sumStockMaster", label: "คลังยอดรวม",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },

    // "exchange": 40,
    //         "sumExchange": 40,

    { key: "exchange", label: "โอนย้าย",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },

    // { key: "sumExchange", label: "รวมโอนย้าย",
    // formatter: (val) => `${val.toLocaleString("en-US")}`, },

    
    { key: "stockPartner", label: "คลังสินค้าที่เปิดบิล",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
    { key: "sumStockPartner", label: "ยอดรวมเปิดบิล",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
    { key: "balance", label: "คลังสินค้าคงเหลือ",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
    { key: "realSale", label: "ยอดลงออเดอร์",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
    { key: "realBalancePartner", label: "รวมยอดลงออเดอร์",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
    { key: "realBalance", label: "คลังสินค้าคงเหลือจริง",
    formatter: (val) => `${val.toLocaleString("en-US")}`, },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const productIdPass = ref("");

  const fetchUsers = (ctx, callback) => {
    console.log("productIdPass.value = ", productIdPass.value);

    store
      .dispatch("warehouses/fetchMyProducts", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        productId: productIdPass.value,
      })
      .then((response) => {
        const { data, total } = response;
        callback(data);
        totalUsers.value = total;
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === true) return "success";
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    productIdPass,
  };
}
